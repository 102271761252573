<template>
  <section class="content">
    <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card" v-if="$gate.isAdmin()">
              <div class="card-header card-header-flex">
                <h3 class="card-title">User List</h3>
                <div class="card-tools">
                  <button type="button" class="btn btn-sm btn-primary" @click="newModal">
                      <i class="fa fa-plus-square"></i>
                      Add New
                  </button>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Type</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Whitelisted</th>
                      <th>Created</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                     <tr v-for="user in users.data" :key="user.id">
                      <td>{{user.id}}</td>
                      <td class="text-capitalize">{{user.type}}</td>
                      <td class="text-capitalize">{{user.name}}</td>
                      <td>{{user.email}}</td>
                      <td>{{user.whitelisted}}</td>
                      <td>{{user.created_at}}</td>
                      <td>
                        <a href="#" @click="editModal(user)">
                            <i class="fa fa-edit blue"></i>
                        </a>
                        /
                        <a href="#" @click="deleteUser(user.id)">
                            <i class="fa fa-trash red"></i>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                  <pagination :data="users" :limit="2" @pagination-change-page="getResults"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <div v-if="!$gate.isAdmin()">
            <not-found></not-found>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addNew" tabindex="-1" role="dialog" aria-labelledby="addNew" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-show="!editMode">Create New User</h5>
                    <h5 class="modal-title" v-show="editMode">Update User's Info</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <!-- <form @submit.prevent="createUser"> -->
                <form @submit.prevent="editMode ? updateUser() : createUser()">
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="form.name" type="text" name="name"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                            <has-error :form="form" field="name"></has-error>
                        </div>
                        <div class="form-group">
                            <label>Email</label>
                            <input v-model="form.email" type="text" name="email"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                            <has-error :form="form" field="email"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>Password</label>
                            <input v-model="form.password" type="password" name="password"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" autocomplete="false">
                            <has-error :form="form" field="password"></has-error>
                        </div>
                    
                        <div class="form-group">
                            <label>User Role</label>
                            <select name="type" v-model="form.type" id="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                              <option value="">Select User Role</option>
                              <option value="admin">Admin</option>
                              <option value="bet88">Bet88</option>
                              <option value="bingo">Bingo Only</option>
                              <option value="branch_report_access">Branch Report Only</option>
                              <option value="mega">Mega</option>
                              <option value="member">Member</option>
                              <option value="pagcor">PAGCOR user</option>
                              <option value="poker_only">Poker Only</option>
                              <option value="slot">Slots Only</option>
                              <option value="sportsbook">Sportsbook Only</option>
                            </select>
                            <has-error :form="form" field="type"></has-error>
                        </div>

                        <div class="form-group">
                            <label>Whitelist Type</label>
                            <select name="whitelisted" v-model="form.whitelisted" id="whitelisted" class="form-control" :class="{ 'is-invalid': form.errors.has('whitelisted') }">
                                <option value="">Select Whitelist Type</option>
                                <option value="ANYWHERE">Anywhere</option>
                                <option value="LIMITED">Limited</option>
                            </select>
                            <has-error :form="form" field="whitelisted"></has-error>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button v-show="editMode" type="submit" class="btn btn-success">Update</button>
                        <button v-show="!editMode" type="submit" class="btn btn-primary">Create</button>
                    </div>
                  </form>
                </div>
            </div>
        </div>
    </div>
  </section>
</template>

<script>
    export default {
      data () {
        return {
          editMode: false,
          users : {},
          form: new Form({
              id : '',
              type : '',
              name: '',
              email: '',
              password: '',
              whitelisted: '',
              email_verified_at: '',
          }),
          error: {}
        }
      },
      methods: {
        getResults(page = 1) {
          this.$Progress.start();
          axios.get('api/user?page=' + page).then(({ data }) => (this.users = data.data));
          this.$Progress.finish();
        },
        updateUser(){
          this.$Progress.start();
          // console.log('Editing data');
          this.form.put('api/user/'+this.form.id)
          .then((response) => {
            // success
            $('#addNew').modal('hide');
            Toast.fire({
              icon: 'success',
              title: response.data.message
            });
            this.$Progress.finish();
                //  Fire.$emit('AfterCreate');
            this.loadUsers();
          })
          .catch(() => {
            this.$Progress.fail();
          });
        },
        editModal(user){
            this.editMode = true;
            this.form.reset();
            $('#addNew').modal('show');
            this.form.fill(user);
        },
        newModal(){
            this.editMode = false;
            this.form.reset();
            $('#addNew').modal('show');
        },
        deleteUser(id){
          Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
              // Send request to the server
              if (result.value) {
                this.form.delete('api/user/'+id).then(()=>{
                  Swal.fire(
                    'Deleted!',
                    'The user has been deleted.',
                    'success'
                  );
                  this.loadUsers();
                }).catch((err) => {
                  this.error = err.response.data;
                  if (typeof this.error === 'string' && this.error.includes('do not')) {
                    Toast.fire({
                      icon: 'error',
                      title: this.error
                    });
                  } else {
                    Swal.fire("Failed!", err.message, "warning");
                  }
                });
              }
          })
        },
        loadUsers(){
          this.$Progress.start();

          if(this.$gate.isAdmin()){
            axios.get("api/user").then(({ data }) => (this.users = data.data));
          }

          this.$Progress.finish();
        },
        createUser() {
          this.form.post('api/user')
            .then((response) => {
              $('#addNew').modal('hide');

              Toast.fire({
                icon: 'success',
                title: response.data.message
              });

              this.$Progress.finish();
              this.loadUsers();
            })
            .catch(err => {
              this.error = err.response.data;
              if (typeof this.error === 'string' && this.error.includes('do not')) {
                Toast.fire({
                  icon: 'error',
                  title: this.error
                });
              } else {
                Toast.fire({
                  icon: 'error',
                  title: 'Some error occurred! Please try again'
                });
              }
            });
        }
      },
      mounted() {
        console.log('User Component mounted.')
      },
      created() {
        this.$Progress.start();
        this.loadUsers();
        this.$Progress.finish();
      }
    }
</script>
