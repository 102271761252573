<template>
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="card" v-if="['admin', 'bet88', 'slot', 'pagcor'].includes($gate.returnMainType())">
            <div class="card-header">
              <h3 class="card-title">Game Report (Slots) - GGR </h3>
            </div>

            <div class="card-header">
              <div class="card-tools">
                  <div class="reportDates input-group">
                    <div class="input-group-prepend">
                      <div class="input-group-text">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar" viewBox="0 0 16 16">
                          <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg>
                      </div>
                    </div>
                    <date-range-picker
                        ref="picker"
                        :opens="opens"
                        :locale-data="{ firstDay: 1, format: 'yyyy-mm-dd' }"
                        :minDate="minDate" :maxDate="maxDate"
                        :timePicker="false"
                        :showDropdowns="showDropdowns"
                        v-model="dateRange"
                        :ranges="ranges"
                        :disabled="isLoading"
                        @update="loadReports"
                    >
                      <template v-slot:input="picker" style="min-width: 350px;">
                        {{ picker.startDate | formatDateRangeInput }} - {{ picker.endDate | formatDateRangeInput }}
                      </template>
                    </date-range-picker>

                    <template>
                      <div class="pl-4">
                        <button class="btn btn-primary" @click="downloadCSV">Download</button>
                      </div>
                    </template>

                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0">
              <table id="dataTable" class="table table-hover">
                <thead class="blue">
                  <tr>
                    <th>Studio</th>
                    <th>GGR</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-if="!isLoading && total_reports.length > 0">
                    <tr v-for="(item, index) in total_reports" :key="index">
                      <td>{{ item.studio }}</td>
                      <td>{{ item.total_revenue | isNotNull | formatAmountFilter }}</td>
                    </tr>
                    <tr style="border-top: 2pt solid gray">
                      <td><b>Grand Total</b></td>
                      <td><b>{{ this.grand_total | formatAmountFilter }}</b></td>
                    </tr>
                  </template>
                  <template v-if="isLoading">
                    <tr>
                      <td colspan="2" style="text-align: center">
                        <spinner :spacing=spinnerSpacing />
                      </td>
                    </tr>
                  </template>
                  <template v-if="total_reports.length <= 0">
                    <tr>
                      <td colspan="2" style="text-align: center">No data was retrieve. Try other date range or different filter.</td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
          </div>
          <!-- /.card -->

          <div v-else>
            <not-found></not-found>
          </div>

        </div>
      </div>

    </div>
  </section>
</template>

<script>

import moment from "moment";
import Spinner from "vue-simple-spinner";

export default {
  components: {
    Spinner,
  },
  data () {
    let startDate = moment().subtract(1, 'd').startOf('day').format('YYYY-MM-DD')
    let endDate = moment().subtract(1, 'd').endOf('day').format('YYYY-MM-DD')

    return {
      total_revenue: {},
      //date-range-picker params
      opens: 'right',
      dateRange: {
        startDate,
        endDate
      },
      minDate: moment('2022-07-19').format('YYYY-MM-DD'),
      maxDate: moment().format('YYYY-MM-DD'),
      timePicker: false,
      timePickerSeconds: true,
      timePickerIncrement: 1,
      showDropdowns: true,
      ranges: this.setRanges(),
      //end date-range-picker params
      isLoading: true,
      spinnerSpacing: 20,
      total_reports: [],
      grand_total: 0,
    }
  },
  methods: {
    logAuditTrail(message) {
      if (!message) return;
      
      let logMessage    = message;

      if (message.includes(":date")) {
        const startDate   = moment(this.dateRange.startDate).format('YYYY-MM-DD HH:mm:ss');
        const endDate     = moment(this.dateRange.endDate).format('YYYY-MM-DD HH:mm:ss');
        logMessage = logMessage.replace(":date", `${startDate} - ${endDate}`);
      }

      this.$auditrail.insertLog('reports-slots-game_report', logMessage);
    },
    async loadReports(){
      this.logAuditTrail(`Viewed (:date)`);

      try {
        this.total_reports = [];
        this.grand_total = 0;

        this.isLoading = true;
        this.$Progress.start();

        let res = await axios.get('/api/v1/summary/game_report_entries', {
          params: {
            startDate: moment(this.dateRange.startDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            endDate: moment(this.dateRange.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
            category: 'SLOTS'
          }
        });
        res = res.data
        this.total_reports = res

        for(let i in this.total_reports) {
          this.grand_total += this.total_reports[i].total_revenue;
        }

      } catch(err) {
        console.error(err)
        Swal.fire(
            'Error loading GGR!',
            err.message + ". Please try again later.",
            'error'
        );
      } finally {
        this.isLoading = false;
        this.$Progress.finish();
      }
    },

    setRanges() {
      let today = new Date()
      today.setHours(0, 0, 0, 0)
      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 999);

      let yesterdayStart = new Date()
      yesterdayStart.setDate(today.getDate() - 1)
      yesterdayStart.setHours(0, 0, 0, 0);

      let yesterdayEnd = new Date()
      yesterdayEnd.setDate(today.getDate() - 1)
      yesterdayEnd.setHours(23, 59, 59, 999);

      let thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      let thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59, 999);

      return {
        'Today': [today, todayEnd],
        'Yesterday': [yesterdayStart, yesterdayEnd],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [
          new Date(today.getFullYear(), 0, 1),
          new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999)
        ],
        'Last month': [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59, 999)
        ],
      }
    },

    downloadCSV() {
      this.logAuditTrail(`Requested to Download (:date)`);

      const table = document.getElementById('dataTable');
      const rows = Array.from(table.querySelectorAll('tr'));
      const csvData = [];
      rows.forEach(row => {
        const cells = Array.from(row.querySelectorAll('th, td'));
        const rowData = cells.map(cell => {
          let cellText = cell.innerText;
          if (cellText.includes(',')) {
            cellText = `"${cellText}"`;
          }
          return cellText;
        }).join(',');
        csvData.push(rowData);
      });
      const csvString = csvData.join('\n');
      const blob = new Blob([csvString], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download',
          'Slots_Games_GGR_' +
          moment(this.dateRange.startDate).format('YYYY-MM-DD') + '_to_' +
          moment(this.dateRange.endDate).format('YYYY-MM-DD') + '.csv');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

  },
  created() {
    this.loadReports();
  },
  filters: {
    isNotNull(value) {
      return (value !== null) ? value : '';
    },
    formatDateRangeInput(value) {
      return moment(value).format('YYYY-MM-DD');
    },
    formatAmountFilter(value) {
      let val = (value).toFixed(2)
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
  }
}

</script>
