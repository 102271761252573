export default class Gate{

    constructor(user){
        this.user = user;
    }

    isAdmin(){
        return this.user.type === 'admin';
    }

    isUser(){
        return this.user.type === 'user';
    }

    isBet88(){
        return this.user.type === 'bet88';
    }    

    isMega(){
        return this.user.type === 'mega';
    }    
    
    isAdminOrUser(){
        if(this.user.type === 'user' || this.user.type === 'admin'){
            return true;
        }
    }

    isMember() {
        return this.user.type === 'member'
    }

    isBingo() {
        return this.user.type === 'bingo'
    }

    isSlot() {
        return this.user.type === 'slot'
    }

    isSportsbook() {
        return this.user.type === 'sportsbook'
    }

    isBranchReportAccess() {
        return this.user.type === 'branch_report_access'
    }

    returnMainType() {
        return this.user.type
    }

    isUserCategoryRoleOnly() {
        if(this.user.type === 'slot' || this.user.type === 'sportsbook' || this.user.type === 'bingo') return true
        return false
    }

    isPokerOnly() {
        return this.user.type === 'poker_only'
    }

    isPagcor() {
        return this.user.type === 'pagcor'
    }
}
